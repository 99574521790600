
import { defineComponent } from "vue";
import { Field } from "vee-validate";
import MotorBreakdown from "@/components/reusable/policy-information/documents-uploads/MotorBreakdown.vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import { useStore } from "vuex";

export default defineComponent({
  name: "policy-certificate-upload-component",
  components: { Field, MotorBreakdown },
  props: {
    redirect: String
  },
  data() {
    return {
      clients: [],
      policies: [],
      vehicles: [],
      policySummary: {},
      shortCode: "",
      clientRoute: "",
      policyDetailsRoute: "",
      clientPublicId: "",
      policyRoute: "",
      policyPublicId: "",
      role: ""
    };
  },
  created() {
    const store = useStore();
    const userType = store.getters.currentUser;

    // Policy document upload routes based on user roles
    if (userType.role == variables.BROKER_USER_ROLE) {
      this.clientRoute = `${variables.BROKER_CLIENTS_LIST_ROUTE}`;
      this.policyRoute = `${variables.BROKER_CLIENT_ROUTE}`;
      this.policyDetailsRoute = `${variables.BROKER_CLIENT_POLICY_DETAILS_ROUTE}`;
    } else if (
      userType.role == variables.ADMIN_USER_ROLE ||
      userType.role == variables.SUPER_ADMIN_USER_ROLE
    ) {
      this.clientRoute = `${variables.SUPERADMIN_CLIENTS_ROUTE}`;
      this.policyRoute = `${variables.SUPERADMIN_CLIENT_ROUTE}`;
      this.policyDetailsRoute = `${variables.SUPERADMIN_CLIENT_POLICY_DETAILS_ROUTE}`;
    }

    // Get client list
    this.getClients();
  },
  methods: {
    // Get list of clients based on user roles
    getClients() {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(this.clientRoute)
          .then(({ data }) => {
            // Assign data to variables
            this.clients = data.data;
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getClientPolicies(event) {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(
          `${this.policyRoute}/${event.target.value}/policies/MOT?withPolicyNumber=true`
        )
          .then(({ data }) => {
            // Assign data to variables
            this.policies = data.data;
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getPolicy(event) {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${this.policyDetailsRoute}/${event.target.value}`)
          .then(({ data }) => {
            // Assign data to variables
            this.policySummary = data.data.policySummary;
            this.vehicles = data.data.items;
            this.shortCode = data.data.policySummary.businessTypeShortCode;
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    }
  }
});
